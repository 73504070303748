import React, { Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import './Previewable.scss';

export const slugTitleCase = (str) => {
  str = str.replace('_', ' ').toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }

  return str.join('');
}

export default function Previewable(WrappedComponent){
  return class extends Component{
    constructor(props) {
      super(props);
      this.state = {
        fetchingData: false,
        hasPreview: false,
        error:false
      }
    }

    async loadData() {
      const { location } = this.props;
      // Fetch preview data and save to state if conditions are met
      const queries = queryString.parse(location.search);
      const postType = queries.posttype && queries.posttype.endsWith('s') ? queries.posttype : `${queries.posttype}s`
      if (queries && queries.preview_id) {
        this.setState({ fetchingData: true, hasPreview: true });
        try {
          const functionsStringUrl = `/.netlify/functions/previews?endpoint=${postType}&preview_id=${queries.preview_id}`;
          // console.log('functionsStringUrl:',functionsStringUrl)
          const previewRes = await axios.get(functionsStringUrl);
          const { data } = previewRes;
          this.setState({ fetchingData: false, newData: data });
        } catch (error) {
          //Try the live page
          try {
            const functionsFallbackStringUrl = `/.netlify/functions/previews?endpoint=${postType}&preview_id=${queries.preview_id}&fallback=true`;
            // console.log('functionsFallbackStringUrl :',functionsFallbackStringUrl )
            const previewRes = await axios.get(functionsFallbackStringUrl);
            const { data } = previewRes;
            this.setState({ fetchingData: false, newData: data });
          } catch (error) {
            this.setState({ error: 'There is no page by that ID', fetchingData: false});
          }
        }
      }
    }

    componentDidMount() {
      this.loadData();
    }

    render() {
      const { data, location } = this.props;
      // console.log(this.props);
      const { fetchingData, hasPreview, newData = {}, error } = this.state;
      const { _embedded = null } = newData;
      // console.log(newData);
      const queries = queryString.parse(location.search);
      const { posttype } = queries;
      // Show error UI
      if (error) return <p>{error}</p>;
      // Show fetching UI
      if (fetchingData) return <div className="fetching-preview"><span>Fetching preview...</span></div>;
      // Show preview data
      if (hasPreview) {
        // Get post type with capitalise first letter
        const postType = posttype && slugTitleCase(posttype);
        // Mimic GraphQL key
        const isCustomPostType = posttype !== 'post' && posttype !== 'page';
        const graphQLKey = `wordpress${isCustomPostType ? 'Wp' : ''}${postType}`;
        // Check for ACF
        const hasAcf = newData.acf && newData.acf.layout;
        // Generate preview data consisting of new and existing data


        // console.log(newData.featuredImage);
        // console.log(newData.featured_media);
        // console.log(newData);

        const previewData = {
          ...data,
          [graphQLKey]: {
            ...data[graphQLKey],
            title: newData.title ? newData.title.rendered : null,
            content: newData.content ? newData.content.rendered : null,
            author: _embedded && _embedded.author ? _embedded.author[0] : null,
            categories: _embedded && _embedded.categories && _embedded.categories.length > 0 ? _embedded.categories[0] : null,
            tags: _embedded && _embedded.tags && _embedded.tags.length > 0 ? _embedded.tags : null,
            featuredImage:  _embedded && _embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'][0] ||  null,
            yoast: {
              metaTitle: 'Preview'
            },
            acf: {
              ...newData.acf,
              layout: hasAcf ? newData.acf.layout.map((item, index) => ({ ...item, __typename: `WordPressAcf_${item.acf_fc_layout}`, id: index })) : []
            },
            sector: newData.sector ? newData.sector : null,
            location: newData.location ? newData.location : null,
            commodity: newData.commodity ? newData.commodity : null,
          }
        };

        // console.log(previewData);
        // console.log(_embedded['wp:featuredmedia'][0].source_url);
        // Return wrapped component with new preview data values overwriting existing
        return <WrappedComponent {...this.props} data={previewData} />
      }
      // Not previewing return wrapped component as is
      return <WrappedComponent {...this.props} />;
    }
  }
}
