import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';

const AcfComponent = ({ location, componentName, item, site }) => {
  // If component does not exist in acf folder, print error message
  if (!(componentName in AcfLayout)) {
    return (
      <div className="wrapper">
        {`Error: Component does not exist. `}
        {`Please create component "${componentName}.jsx" in src/acf folder `}
        {`and add export to src/acf/index.js`}
      </div>
    );
  }
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      location={location}
      site={site}
      {...item}
    />
  );
};

const Page = ({ data, location }) => {
  const { wordpressPage: page, site } = data;
  if (!page) return null;
  const { title, yoast, acf = {} } = page;
  const { layout } = acf;
  const { title: siteTitle } = site.siteMetadata;
  return (
    <Layout location={location}>
      <SEO
        title={`${
          yoast.metaTitle ?
          decodeEntities(yoast.metaTitle) :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      {layout && layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            site={site}
          />
        );
      })}
      {/* {console.log(location)} */}
    </Layout>
  )
}

export default Previewable(Page);

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        title,
        wordpressUrl
      }
    }
    wordpressPage(id: { glob: $id }) {
      title
      content
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          __typename,
          ... on WordPressAcf_IntroSection {
            id
            title
            subtitle
          }
          ... on WordPressAcf_Projects {
            id
            regions {
              regionName
              projectTotal
              studiesTotal
              commoditiesTotal
            }
          }
          ... on WordPressAcf_Hero {
            id
            subheading
            heading
            blurb
            backgroundImage {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90, srcSetBreakpoints: [ 1600 ]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt_text
            }
          }
          ... on WordPressAcf_HomeHero {
            id
            heading
            multiHeading {
              headerOne
              headerTwo
            }
            backgroundImage {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90, srcSetBreakpoints: [ 1600]) { 
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt_text
            }
            backgroundImageCarousel {
              id
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90, srcSetBreakpoints: [ 1600]) { 
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundImageCarouselDuration
            ctaButton
            buttonText
          }
          ... on WordPressAcf_TextBlock {
            id
            theme
            subheading
            heading
            content
            button
            buttonText
          }
          ... on WordPressAcf_ImageAndText {
            id
            imagePlacement
            theme
            style
            subheading
            heading
            content
            button
            buttonHash
            buttonText
            image {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 728, quality: 90, srcSetBreakpoints: [ 728 ]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              },
              alt_text,
            }
          }
          ... on WordPressAcf_IconsAndText {
            id
            iconsPlacement
            theme
            subheading
            heading
            content
            button
            buttonText
            panels {
              panelHeading
              panelSubheading
              icons {
                icon {
                  source_url
                }
                label
              }
            }
          }
          ... on WordPressAcf_Icons {
            id
            iconsPerRow
            theme
            icons {
              icon {
                source_url
              }
              heading
              description
            }
          }
          ... on WordPressAcf_ProjectsFeed {
            optionalText
            manuallySelectCaseStudies
            caseStudiesList {
              caseStudyItem {
                guid
                post_title
              }
            }
            customFilterLink
            customButtonText
          }
          ... on WordPressAcf_FeaturedProjectsSliderFeed {
            optionalText
            manuallySelectCaseStudies
            caseStudiesList {
              featuredCaseStudyItem {
                guid
                post_title
              }
              featuredCaseStudyItemInfo {
                title
                location
                sector
                companyName
                blurbText
              }
            }
            customFilterLink
            customButtonText
          }
          ... on WordPressAcf_Cta {
            id
            heading
            content
            button
            buttonHash
            buttonText
            backgroundImage {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90, srcSetBreakpoints: [ 1600 ], grayscale: true) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              },
              alt_text,
            }
          }
          ... on WordPressAcf_KeyStatisticsCounter {
            id
            statistics {
              label
              valuePrefix
              value
              valueSuffix
            }
          }
          ... on WordPressAcf_ContentWithSidebar {
            id
            sidebarLabel
            enableDisable
            sections {
              heading
              icon {
                source_url
              }
              content
              buttonOne
              buttonOneText
              buttonOneHash
              buttonTwo
              buttonTwoText
              buttonTwoHash
              contactCards {
                locationName
                streetAddress
                mailingAddress
                telephone
                fax
                googleMapsLink
              }
            }
          }
          ... on WordPressAcf_SteppedPanels {
            id
            subheading
            heading
            content
            panels {
              title
              description
              image {
                id,
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1024, quality: 90, srcSetBreakpoints: [ 1024 ]) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                },
                alt_text,
              }
            }
          }
          ... on WordPressAcf_ContactForm {
            id
            theme
            formType
            subheading
            heading
            content
          }
          ... on WordPressAcf_HomeTextContentArea {
            id
            Content {
              contentCopy
              button
              buttonText
              toggleOptions {
                toggleArrowDown
                toggleSquares
              }
              backgroundImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2100, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_HomeTextAndVideoImage {
            id
            subtitle
            heading
            copyContent
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAccent
            buttonLink
            buttonText
          }
          ... on WordPressAcf_HomeThreeImage {
            id
            textContent {
              heading
              subtitle
              textContent
            }
            images {
              imageLeft {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageRightTop {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageRightBot {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WordPressAcf_HomeTextAndCounter {
            id
            copyContent
            heading
            buttonText
            buttonLink
            numbers {
              metricOne {
                statistics {
                  label
                  valuePrefix
                  value
                  valueSuffix
                }
              }
              metricTwo {
                statistics {
                  label
                  valuePrefix
                  value
                  valueSuffix
                }
              }
              metricThree {
                statistics {
                  label
                  valuePrefix
                  value
                  valueSuffix
                } 
              }
            }
          }
          ... on WordPressAcf_ContactUsBox {
            id
            subtitle
            heading
            buttonLink
            buttonText
            content
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
