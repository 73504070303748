import React, { Component } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import './Image.scss';

export default class Image extends Component {
  render() {
    const { image, className = '', ...props } = this.props;
    if (
      (image && image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid) ||
      (image && image.childImageSharp && image.childImageSharp.fluid)
    ) {
      return (
        <Img
          {...props}
          className={className}
          fluid={image.localFile ? image.localFile.childImageSharp.fluid : image.childImageSharp.fluid}
          durationFadeIn={1000}
        />
      );
    }
    if (image && image.url) {
      return (
        <div className={`gatsby-image-wrapper ${className}`} style={props.style}>
          <img src={image.url} alt={image.alt || ''} style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "50% 50%",
            transitionDelay: "1000ms"
          }} />
        </div>);
    }
    if (image && image.source_url) {
      return (
        <div className={`gatsby-image-wrapper ${className}`} style={props.style}>
          <div style={{width: "100%", paddingBottom: "71.7636%" }}></div>
          <img src={image.source_url} alt={image.alt || ''} style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "50% 50%",
            transitionDelay: "1000ms"
          }} />
        </div>);
    }
    return <div className={`gatsby-image-wrapper placeholder ${className}`} />;
  }
}
